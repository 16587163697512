import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import Promo from './promo';
import PromoCarousel from './promoCarousel';
import Store from 'store';
import Dates from '../../utils/DateFormat';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default class MyResponsiveGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCustomiseMode: false,
      isDirty: false,
      rowHeight: 255,
      promos: [],
      promoPositions: null,
      vehicle: null,
      customer: Store.get('customerInfo'),
      rewardsSlides: carouselArray(Store.get('offers')),
      competitionsSlides: [],
      config: {},
      HyundaiMagazineSlides: [],
      newOfferSlides: [],
      newsSlides: [
        {
          isRoute: true,
          linkPath: {
            pathname: '/hyundaiNews',
            query: { displayItem: 'inster' },
          },
          imagePath: 'images/insterTile.jpg',
          imageAlt:
            'Hyundai unveils INSTER Cross: a versatile electric city car wrapped in an outdoor-focused design',
          text: 'Hyundai unveils INSTER Cross: a versatile electric city car wrapped in an outdoor-focused design',
          overlay: 'Hyundai News',
        },
        {
          isRoute: true,
          linkPath: {
            pathname: '/awardsNews',
            query: { displayItem: 'I5NFun' },
          },
          imagePath: 'images/i5nFunTile.jpg',
          imageAlt:
            'Hyundai IONIQ 5 N named Best Fun Car in 2025 Parkers New Car Awards',
          text: 'Hyundai IONIQ 5 N named Best Fun Car in 2025 Parkers New Car Awards',
          overlay: 'Awards',
        },
        {
          isRoute: true,
          linkPath: {
            pathname: '/awardsNews',
            query: { displayItem: 'I5NHotHatch' },
          },
          imagePath: 'images/i5nHotHatchTile.jpg',
          imageAlt:
            'Hyundai IONIQ 5 N Named Hot Hatch of the Year by The Sunday Times at the 2024 News UK Motor Awards',
          text: 'Hyundai IONIQ 5 N Named Hot Hatch of the Year by The Sunday Times at the 2024 News UK Motor Awards',
          overlay: 'Awards',
        },
        {
          isRoute: true,
          linkPath: {
            pathname: '/hyundaiNews',
            query: { displayItem: 'i5npricing' },
          },
          imagePath: 'images/i5npricingtile.jpg',
          imageAlt:
            'Hyundai Motor UK reveals New IONIQ 5 pricing and specification',
          text: 'Hyundai Motor UK reveals New IONIQ 5 pricing and specification',
          overlay: 'Hyundai News',
        },
        {
          isRoute: true,
          linkPath: {
            pathname: '/awardsNews',
            query: { displayItem: 'Newioniq5N' },
          },
          imagePath: 'images/newIoniq5ntile2.jpg',
          imageAlt:
            'Hyundai IONIQ 5 N named Best Performance Car at Autocar Awards 2024',
          text: 'Hyundai IONIQ 5 N named Best Performance Car at Autocar Awards 2024',
          overlay: 'Awards',
        },
        {
          isRoute: true,
          linkPath: {
            pathname: '/awardsNews',
            query: { displayItem: 'Nioniq5N' },
          },
          imagePath: 'images/5nTile.jpg',
          imageAlt:
            'Hyundai IONIQ 5 N Charges On With 2024 World Performance Car Award Victory',
          text: 'Hyundai IONIQ 5 N Charges On With 2024 World Performance Car Award Victory',
          overlay: 'Awards',
        },
        {
          isRoute: true,
          linkPath: {
            pathname: '/hyundaiNews',
            query: { displayItem: 'hyundaiTucson' },
          },
          imagePath: 'images/hytucTile.jpg',
          imageAlt:
            'Hyundai announces prices and specifications for New Tucson',
          text: 'Hyundai announces prices and specifications for New Tucson',
          overlay: 'Hyundai News',
        },
        {
          isRoute: true,
          linkPath: {
            pathname: '/hyundaiNews',
            query: { displayItem: 'tucsonHybrid' },
          },
          imagePath: 'images/thybridTile.jpg',
          imageAlt:
            'Hyundai offers new TUCSON Hybrid customers complimentary upgrade worth £2,200',
          text: 'Hyundai offers new TUCSON Hybrid customers complimentary upgrade worth £2,200',
          overlay: 'Hyundai News',
        },
        {
          isRoute: true,
          linkPath: {
            pathname: '/hyundaiNews',
            query: { displayItem: 'npx1' },
          },
          imagePath: 'images/npx1Tile.png',
          imageAlt:
            'Hyundai Motor Unveils ‘NPX1’ Concept Model at Tokyo Auto Salon',
          text: 'Hyundai Motor Unveils ‘NPX1’ Concept Model at Tokyo Auto Salon',
          overlay: 'Hyundai News',
        },
        {
          isRoute: true,
          linkPath: {
            pathname: '/hyundaiNews',
            query: { displayItem: 'newBayon' },
          },
          imagePath: 'images/newBayonTile.png',
          imageAlt: 'New Bayon: A stylish SUV with class-leading practicality',
          text: 'New Bayon: A stylish SUV with class-leading practicality',
          overlay: 'Hyundai News',
        },
        {
          isRoute: true,
          linkPath: {
            pathname: '/awardsNews',
            query: { displayItem: 'I5n' },
          },
          imagePath: 'images/i5nTile.jpg',
          imageAlt:
            'Groundbreaking Hyundai IONIQ 5 N named Car of the Year by TopGear.com',
          text: 'Groundbreaking Hyundai IONIQ 5 N named Car of the Year by TopGear.com',
          overlay: 'Awards',
        },
        {
          isRoute: true,
          linkPath: {
            pathname: '/hyundaiNews',
            query: { displayItem: 'NewIoniq5n' },
          },
          imagePath: 'images/newIoniq5nTile.jpg',
          imageAlt:
            'Hyundai Motor UK announces IONIQ 5 N range pricing and specifications',
          text: 'Hyundai Motor UK announces IONIQ 5 N range pricing and specifications',
          overlay: 'Hyundai News',
        },
        {
          isRoute: true,
          linkPath: {
            pathname: '/hyundaiNews',
            query: { displayItem: 'newI2023' },
          },
          imagePath: 'images/newi2023Tile.jpg',
          imageAlt: 'Hyundai announces new i20 prices and specifications',
          text: 'Hyundai announces new i20 prices and specifications',
          overlay: 'Hyundai News',
        },
        {
          isRoute: true,
          linkPath: {
            pathname: '/hyundaiNews',
            query: { displayItem: 'newSantaFe23' },
          },
          imagePath: 'images/santafe23Tile.jpg',
          imageAlt: 'Hyundai’s All-New Santa Fe Boasts Bold New Design',
          text: 'Hyundai’s All-New Santa Fe Boasts Bold New Design',
          overlay: 'Hyundai News',
        },
        {
          isRoute: true,
          linkPath: {
            pathname: '/hyundaiNews',
            query: { displayItem: 'konaCamp' },
          },
          imagePath: 'images/konaCampTile.jpg',
          imageAlt:
            'Hyundai celebrates the power of imagination in new KONA campaign',
          text: 'Hyundai celebrates the power of imagination in new KONA campaign',
          overlay: 'Hyundai News',
        },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'newKona23' },
        //   },
        //   imagePath: 'images/newKona23Tile.jpg',
        //   imageAlt: 'All-New KONA crowned Car of the Year',
        //   text: 'All-New KONA crowned Car of the Year',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'ioniq5n' },
        //   },
        //   imagePath: 'images/ioniq5ntile.jpg',
        //   imageAlt:
        //     'Hyundai Motor’s IONIQ 5 N Debuts at Goodwood Festival of Speed',
        //   text: 'Hyundai Motor’s IONIQ 5 N Debuts at Goodwood Festival of Speed',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'newi20' },
        //   },
        //   imagePath: 'images/newi20Tile.jpg',
        //   imageAlt: 'New i20 attracts with elegant and sporty design’ ',
        //   text: 'New i20 attracts with elegant and sporty design ',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/awardsNews',
        //     query: { displayItem: 'ioniq6Award' },
        //   },
        //   imagePath: 'images/ioniq6Tile.png',
        //   imageAlt:
        //     'Hyundai IONIQ 6 Sweeps World Car of the Year, World Electric Vehicle and World Car Design of the Year',
        //   text: 'Hyundai IONIQ 6 Sweeps World Car of the Year',
        //   overlay: 'Awards',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'arcticEnvironment' },
        //   },
        //   imagePath: 'images/arcticTile.jpg',
        //   imageAlt: 'Hyundai’s IONIQ 5 N High-performance EV Prototype’ ',
        //   text: 'Hyundai’s IONIQ 5 N High-performance EV Prototype ',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'ioniq6Saloon' },
        //   },
        //   imagePath: 'images/ioniq6SaloonTile.jpg',
        //   imageAlt: 'New Hyundai IONIQ 6 Named ‘Saloon of the Year’ ',
        //   text: 'New Hyundai IONIQ 6 Named ‘Saloon of the Year’ ',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/awardsNews',
        //     query: { displayItem: 'tucsonHonest' },
        //   },
        //   imagePath: 'images/tucsonhonestTile.jpeg',
        //   imageAlt:
        //     'Hyundai TUCSON ranked as most satisfying car in the Honest John Satisfaction Index 2022',
        //   text: 'Hyundai TUCSON ranked as most satisfying car in the Honest John Satisfaction Index 2022',
        //   overlay: 'Awards',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/awardsNews',
        //     query: { displayItem: 'ioniq5winner' },
        //   },
        //   imagePath: 'images/ioniq5mainTile.jpg',
        //   imageAlt: 'Hyundai IONIQ 5 awarded Large Electric Car of the Year',
        //   text: 'Hyundai IONIQ 5 awarded Large Electric Car of the Year',
        //   overlay: 'Awards',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/awardsNews',
        //     query: { displayItem: 'ioniq6' },
        //   },
        //   imagePath: 'images/ionic6bestTile.jpeg',
        //   imageAlt: 'Hyundai IONIQ 6 is Euro NCAP’s ‘Best in Class’',
        //   text: 'Hyundai IONIQ 6 is Euro NCAP’s ‘Best in Class’',
        //   overlay: 'Awards',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/awardsNews',
        //     query: { displayItem: 'i20nWinner' },
        //   },
        //   imagePath: 'images/rewardi20nTile.jpeg',
        //   imageAlt: 'Major wins for Hyundai i20 N',
        //   text: 'Major wins for Hyundai i20 N',
        //   overlay: 'Awards',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'largestMarketShare' },
        //   },
        //   imagePath: 'images/largestmarketshareTile.jpg',
        //   imageAlt: 'Largest Market Share',
        //   text: 'Largest Market Share',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'expo2030' },
        //   },
        //   imagePath: 'images/expo2030Tile.jpeg',
        //   imageAlt: 'World Expo 2030',
        //   text: 'World Expo 2030',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'whatsInaName' },
        //   },
        //   imagePath: 'images/whatsinanameTile.jpeg',
        //   imageAlt: 'What’s in a name?',
        //   text: 'What’s in a name?',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'oneMillionPound' },
        //   },
        //   imagePath: 'images/onemillionheaderTile.png',
        //   imageAlt: 'Hyundai pledges £1m for school trips to remember',
        //   text: 'Hyundai pledges £1m for school trips to remember',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'execChair' },
        //   },
        //   imagePath: 'images/execchairTile.jpeg',
        //   imageAlt:
        //     'Hyundai Motor Group Executive Chair Euisun Chung Named MotorTrend Person of the Year',
        //   text: 'Hyundai Motor Group Executive Chair Euisun Chung Named MotorTrend Person of the Year',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: false,
        //   linkPath:
        //     ' https://www.youtube.com/watch?v=B21HKI6DJnk&ab_channel=HyundaiUK',
        //   imagePath: 'images/bluelinkTile.png',
        //   imageAlt: 'News',
        //   text: 'Bluelink Wireless Update now available',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/awardsNews',
        //     query: { displayItem: 'businessAwards' },
        //   },
        //   imagePath: 'images/businessheader.jpg',
        //   imageAlt: 'Hyundai TUCSON and IONIQ 5 triumph at Business Car Awards',
        //   text: 'Hyundai TUCSON and IONIQ 5 triumph at Business Car Awards',
        //   overlay: 'Awards',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/awardsNews',
        //     query: { displayItem: 'ioniq5' },
        //   },
        //   imagePath: 'images/ionheader.jpg',
        //   imageAlt: 'Hyundai IONIQ 5 scoops multiple honours',
        //   text: 'Hyundai IONIQ 5 scoops multiple honours',
        //   overlay: 'Awards',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/awardsNews',
        //     query: { displayItem: 'bestCarAwards' },
        //   },
        //   imagePath: 'images/bcaheader1.jpg',
        //   imageAlt: 'Tucson car of the year',
        //   text: 'TUCSON named "Car of the Year"',
        //   overlay: 'Awards',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'electricSUV' },
        //   },
        //   imagePath: 'images/suvheader.jpg',
        //   imageAlt: 'Electric SUV',
        //   text: 'Hyundai Motor Teases Sneak Peek of SEVEN,All-Electric SUV Concept',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'i20n' },
        //   },
        //   imagePath: 'images/i20nheader.jpg',
        //   imageAlt: 'i20N',
        //   text: 'Hyundai i20 N crowned Top Gear Speed Week Champion',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'hyundaiEv' },
        //   },
        //   imagePath: 'images/hyundaievheader.jpg',
        //   imageAlt: 'Hyundai Ev',
        //   text: 'Hyundai leads industry desirability rankings as consumers tune into EVs',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'customerBrand' },
        //   },
        //   imagePath: 'images/customerbrandheader.jpg',
        //   imageAlt: 'Customer Brand',
        //   text: 'Hyundai delivers leading customer brand experience in 2021',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'commission' },
        //   },
        //   imagePath: 'images/commissionheader.jpg',
        //   imageAlt: 'Commission',
        //   text: 'Hyundai Motor and Tate Announce the Opening ofthe Hyundai Commission: Anicka Yi: In Love With The World',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'fashionCollection' },
        //   },
        //   imagePath: 'images/fashioncollectionheader.png',
        //   imageAlt: 'Fashion Collection',
        //   text: 'Hyundai Motor Launches ‘Re:Style 2021’ Fashion Collection Repurposing Discarded Vehicle Materials     ',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'pony' },
        //   },
        //   imagePath: 'images/ponytile.jpg',
        //   imageAlt: 'Pony',
        //   text: 'Hyundai Showcases Heritage Series PONY ',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'healthySeas' },
        //   },
        //   imagePath: 'images/hstile.jpg',
        //   imageAlt: 'Healthy seas',
        //   text: 'Hyundai partners with Healthy Seas ',
        //   overlay: 'Hyundai News',
        // },
        // // {
        // //   isRoute: true,
        // //   linkPath: {
        // //     pathname: '/awardsNews',
        // //     query: { displayItem: 'GQAwards' },
        // //   },
        // //   imagePath: 'images/CarAwardsTile.jpg',
        // //   imageAlt: 'Car Awards',
        // //   text: 'Car GQ Awards',
        // //   overlay: 'Awards',
        // // },

        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/awardsNews',
        //     query: { displayItem: 'januaryAwards' },
        //   },
        //   imagePath: 'images/jantile.jpg',
        //   imageAlt: 'January Awards',
        //   text: 'January Awards',
        //   overlay: 'Awards',
        // },

        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/awardsNews',
        //     query: { displayItem: 'Feefo' },
        //   },
        //   imagePath: 'images/feefotile.jpg',
        //   imageAlt: 'Gold Award',
        //   text: 'Gold Trusted Service Award',
        //   overlay: 'Awards',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/productNews',
        //     query: { displayItem: 'konaN' },
        //   },
        //   imagePath: 'images/konaN1Tile.jpg',
        //   imageAlt: 'Kona N',
        //   text: 'Hyundai Motor to Unveil The All-New KONA N',
        //   overlay: 'Product News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/productNews',
        //     query: { displayItem: 'ioniq5' },
        //   },
        //   imagePath: 'images/ioniq5tile.jpg',
        //   imageAlt: 'Ioniq 5',
        //   text: 'High demand for IONIQ 5 in Europe',
        //   overlay: 'Product News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/productNews',
        //     query: { displayItem: 'konaElectric' },
        //   },
        //   imagePath: 'images/hyundaielectrictile.jpg',
        //   imageAlt: 'Kona Electric',
        //   text: 'Longest range of any grant-eligible vehicle',
        //   overlay: 'Product News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/productNews',
        //     query: { displayItem: 'Bayon' },
        //   },
        //   imagePath: 'images/bayontile.jpg',
        //   imageAlt: 'Bayon',
        //   text: 'Hyundai Motor reveals all-new Bayon',
        //   overlay: 'Product News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/productNews',
        //     query: { displayItem: 'newSantaFe' },
        //   },
        //   imagePath: 'images/hyundai-uk-santa-fe-0421-tile.jpg',
        //   imageAlt: 'New Santa Fe',
        //   text: 'Hyundai announces New SANTA FE prices ',
        //   overlay: 'Product News',
        // },

        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/productNews',
        //     query: { displayItem: 'tusconNLine' },
        //   },
        //   imagePath: 'images/all-newTUCSONNLinetile.jpg',
        //   imageAlt: 'Tucson N Line',
        //   text: 'First all new TUCSON N Line',
        //   overlay: 'Product News',
        // },

        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/productNews',
        //     query: { displayItem: 'newi20' },
        //   },
        //   imagePath: 'images/i201fronttile.jpg',
        //   imageAlt: 'newi20',
        //   text: 'Hyundai Motor teases new design for the all-new i20',
        //   overlay: 'Product News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/awardsNews',
        //     query: { displayItem: 'twoAwards' },
        //   },
        //   imagePath: 'images/twoawardstile.png',
        //   imageAlt: 'twoAwards',
        //   text: 'Hyundai UK scoops two prizes at the Company Car Today awards',
        //   overlay: 'Awards',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'urbanMobility' },
        //   },
        //   imagePath: 'images/urbanMobilityTile.jpg',
        //   imageAlt: 'urbanMobility',
        //   text: 'The Future According to Hyundai',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'airTaxi' },
        //   },
        //   imagePath: 'images/airTaxiTile.jpg',
        //   imageAlt: 'airTaxi',
        //   text: 'Hyundai and Uber team up to create Air Taxis',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/productNews',
        //     query: { displayItem: 'newi10' },
        //   },
        //   imagePath: 'images/newi101frontile.jpg',
        //   imageAlt: 'newi10',
        //   text: 'All New i10 is a big deal',
        //   overlay: 'Product News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'fountain' },
        //   },
        //   imagePath: 'images/fountain_tile_510x189.jpg',
        //   imageAlt: 'fountain',
        //   text: 'Hyundai Motor, Tate Unveil Kara Walker’s Monumental Fountain',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'EvConcept' },
        //   },
        //   imagePath: 'images/45ev.jpg',
        //   imageAlt: 'EvConcept',
        //   text: 'Hyundai defines its future with the 45 EV Concept',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/awardsNews',
        //     query: { displayItem: 'bestEV' },
        //   },
        //   imagePath: 'images/best-ev_510.jpg',
        //   imageAlt: 'bestEV',
        //   text: "Kona Electric wins 'BEST EV' title at 2019 Honest John Awards",
        //   overlay: 'Awards',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'smartPhoneKey' },
        //   },
        //   imagePath: 'images/hyundai_digi_door_510.jpg',
        //   imageAlt: 'smartPhoneKey',
        //   text: 'Hyundai Motor Group Develops Smartphone-based Digital Key',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/hyundaiNews',
        //     query: { displayItem: 'future' },
        //   },
        //   imagePath: 'images/futurenewsh.jpg',
        //   imageAlt: 'future',
        //   text: 'The future is 15 metres ahead of you',
        //   overlay: 'Hyundai News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/productNews',
        //     query: { displayItem: 'ironMan' },
        //   },
        //   imagePath: 'images/iron1.jpg',
        //   imageAlt: 'ironMan',
        //   text: 'New Kona Iron Man Edition is fit for a superhero',
        //   overlay: 'Product News',
        // },
        // {
        //   isRoute: true,
        //   linkPath: {
        //     pathname: '/awardsNews',
        //     query: { displayItem: 'whatCar' },
        //   },
        //   imagePath: 'images/whatcar_510.jpg',
        //   imageAlt: 'whatCar',
        //   text: 'A four to be reckoned with at What Car? Awards',
        //   overlay: 'Awards',
        // },
      ],
      ownershipSlides: [],
    };

    function carouselArray(arr) {
      var returnArr = [];
      for (var { carousel } of arr) {
        returnArr.push(carousel);
      }
      return returnArr;
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.vehicle == null || nextProps.customer == null) {
      return null;
    }

    var vehicleChanged =
      prevState.vehicle == null || nextProps.vehicle.id != prevState.vehicle.id;
    var customerChanged = prevState.customer == null;

    if (!vehicleChanged && !customerChanged) {
      return null;
    }

    var ownershipSlides = [];

    if (vehicleChanged) {
      if (MyResponsiveGrid.displaySlide(nextProps.vehicle.nextServiceDate)) {
        ownershipSlides.push({
          isRoute: true,
          linkPath: '/myReminders',
          imagePath: 'images/ownershipinfo/service.png',
          imageAlt: 'Your next service',
          text: 'Your next service',
          title: 'Your next service',
          overlay: MyResponsiveGrid.buildOverlay(
            'Next service',
            nextProps.vehicle.nextServiceDate,
          ),
        });
      }
      if (MyResponsiveGrid.displaySlide(nextProps.vehicle.insuranceRenewal)) {
        ownershipSlides.push({
          isRoute: true,
          linkPath: '/myReminders',
          imagePath: 'images/ownershipinfo/insurance.png',
          imageAlt: 'Your insurance renewal',
          text: 'Your insurance renewal',
          title: 'Your insurance renewal',
          overlay: MyResponsiveGrid.buildOverlay(
            'Car insurance',
            nextProps.vehicle.insuranceRenewal,
          ),
        });
      }
      if (MyResponsiveGrid.displaySlide(nextProps.vehicle.roadTaxDue)) {
        ownershipSlides.push({
          isRoute: true,
          linkPath: '/myReminders',
          imagePath: 'images/ownershipinfo/road-tax.png',
          imageAlt: 'Your road tax',
          text: 'Your road tax',
          title: 'Your road tax',
          overlay: MyResponsiveGrid.buildOverlay(
            'Road tax',
            nextProps.vehicle.roadTaxDue,
          ),
        });
      }
      if (MyResponsiveGrid.displaySlide(nextProps.vehicle.nextMotDate)) {
        ownershipSlides.push({
          isRoute: true,
          linkPath: '/myReminders',
          imagePath: 'images/ownershipinfo/mot.png',
          imageAlt: 'Your next MOT',
          text: 'Your next MOT',
          title: 'Your next MOT',
          overlay: MyResponsiveGrid.buildOverlay(
            'Next MOT',
            nextProps.vehicle.nextMotDate,
          ),
        });
      }
      if (
        MyResponsiveGrid.displaySlide(nextProps.vehicle.breakdownCoverRenewal)
      ) {
        ownershipSlides.push({
          isRoute: true,
          linkPath: '/myReminders',
          imagePath: 'images/ownershipinfo/breakdown.png',
          imageAlt: 'Your breakdown cover',
          text: 'Your breakdown cover',
          title: 'Your breakdown cover',
          overlay: MyResponsiveGrid.buildOverlay(
            'Breakdown cover',
            nextProps.vehicle.breakdownCoverRenewal,
          ),
        });
      }

      if (ownershipSlides.length === 0) {
        ownershipSlides.push({
          isRoute: true,
          linkPath: '/myReminders',
          imagePath: 'images/ownershipinfo/default.png',
          imageAlt: 'Your reminders',
          text: 'My Reminders',
          title: 'My Reminders',
        });
        // ownershipSlides.push({
        //   isRoute: true,
        //   linkPath: '/fuelCalculator',
        //   imagePath: 'images/fuelcalc2tile.jpg',
        //   imageAlt: 'Fuel Saving Calculator',
        //   text: 'Fuel Saving Calculator',
        //   title: 'Fuel Calculator',
        // });
      }
    } else {
      ownershipSlides = prevState.ownershipSlides;
    }

    var myHyundaiMagazineSlides = [];
    // myHyundaiMagazineSlides.push({
    //   isRoute: false,
    //   linkPath: 'https://www.hyundaiexperience.com/environment/exterior',
    //   imagePath: 'images/virtualExp.jpg',
    //   imageAlt: 'Virtual Experience Centre',
    //   title: 'Virtual Experience Centre',
    //   text: <span>Explore Now</span>,
    //   target: 'blank',
    // });

    //these are populated here so we can filter by interests if needed
    var competitionsSlides = [];
    const competitions = Store.get('competitionInfo');

    competitions.forEach((competition, index) => {
      if (!competition.directLinkOnly) {
        competitionsSlides.push({
          isRoute: true,
          linkPath: '/competition?id=' + competition.id,
          imagePath: competition.homepageImage,
          imageAlt: competition.competitionPageImageAlternativeText,
          title: competition.longTitle,
          text: <span>{competition.longTitle}</span>,
        });
      }
    });

    if (
      competitionsSlides.length === 0 ||
      competitionsSlides.filter(
        (competition) => competition.directLinkOnly === true,
      ).length === competitionsSlides.length
    ) {
      competitionsSlides.push({
        isRoute: false,
        linkPath: '',
        imagePath: 'images/check.jpg',
        imageAlt: '',
        title: 'Nothing to see',
        text: <span>Please check back soon.</span>,
      });
    }

    return {
      ...prevState,
      vehicle: nextProps.vehicle,
      ownershipSlides: ownershipSlides,
      customer: nextProps.customer,
      competitionsSlides: competitionsSlides,
      HyundaiMagazineSlides: myHyundaiMagazineSlides,
    };
  }

  static displaySlide(date) {
    if (date == null) {
      return false;
    }
    var parsedDate = new Date(date);
    if (parsedDate.getFullYear() === 1) {
      return false;
    }

    var today = new Date();
    if (today > parsedDate) {
      return false;
    }

    return true;
  }

  static buildOverlay(text, date) {
    var today = new Date();
    var parsedDate = new Date(date);

    var days = Dates.getDaysDifference(today, parsedDate);

    return (
      <span>
        <span className="d-none d-sm-inline">{text} is due in: </span>
        <br />
        <strong>
          <span className="bigger-text">{days}</span> days
        </strong>
      </span>
    );
  }

  componentDidMount() {
    let promoPositions = Store.get('homeItemGrid');

    const { customer } = this.state;

    this.setState({
      config: Store.get('public_config'),
    });

    let twoColumn = [];

    if (promoPositions == null) {
      //console.log(customer.customerType);
      //if (customer && customer.customerType === 1) {
      //  twoColumn = [
      //    { i: 'rewards', x: 0, y: 0, w: 1, h: 1 },
      //    { i: 'competitions', x: 1, y: 0, w: 1, h: 1 },
      //    { i: 'news', x: 0, y: 1, w: 1, h: 1 },
      //    { i: 'happening', x: 1, y: 1, w: 1, h: 1 },
      //    { i: 'productNews', x: 0, y: 2, w: 1, h: 1 },
      //    { i: 'ownership', x: 1, y: 2, w: 1, h: 1 },
      //  ];

      //   promoPositions = {
      //     lg: twoColumn,
      //   };
      // } else {

      // }
      twoColumn = [
        { i: 'rewards', x: 0, y: 0, w: 1, h: 1 },
        { i: 'news', x: 1, y: 0, w: 1, h: 1 },
        { i: 'ownership', x: 0, y: 1, w: 1, h: 1 },
        { i: 'whn', x: 1, y: 1, w: 1, h: 1 },
        { i: 'competitions', x: 0, y: 2, w: 1, h: 2 },
      ];

      promoPositions = {
        lg: twoColumn,
      };

      Store.set('homeItemGrid', promoPositions);
    }

    this.setState({
      promoPositions: promoPositions,
    });
  }

  handleBreakpointChange = (newBreakpoint, newCols) => {
    var newRowHeight = 255;
    if (newBreakpoint === 'sm') {
      newRowHeight = 215;
    } else if (newBreakpoint === 'xs') {
      newRowHeight = 293;
    } else if (newBreakpoint === 'xxs') {
      newRowHeight = 195;
    }

    this.setState({
      rowHeight: newRowHeight,
    });
  };

  handleLayoutChange = (layout, allLayouts) => {
    this.setState((prevState, props) => ({
      isDirty: prevState.isCustomiseMode ? true : prevState.isDirty,
      promoPositions: allLayouts,
    }));
  };

  handleCustomiseModeStateChange = () => {
    this.setState((prevState, props) => ({
      isCustomiseMode: !prevState.isCustomiseMode,
    }));
  };

  handleSaveLayoutClick = () => {
    console.log(this.state.promoPositions);
    if (this.state.isDirty) {
      Store.set('homeItemGrid', this.state.promoPositions);
    }

    this.setState({
      isDirty: false,
      isCustomiseMode: false,
    });
  };

  render() {
    const promoPositions = this.state.promoPositions;

    let promoContent = [
      {
        key: 'rewards',
        name: 'Offers',
        slides: this.state.rewardsSlides,
        link: '/offers',
      },
      {
        key: 'ownership',
        name: 'My Car',
        slides: this.state.ownershipSlides,
        link: '/myCarDetails',
      },
      {
        key: 'news',
        name: 'News',
        slides: this.state.newsSlides,
        link: '/productNews',
      },
      {
        key: 'whn',
        name: 'Virtual Experience Centre',
        slides: this.state.HyundaiMagazineSlides,
        link: 'https://www.hyundaiexperience.com/environment/exterior',
      },
    ];

    if (
      this.state.config &&
      this.state.config.data &&
      this.state.config.data.competitions_active
    ) {
      const comp = {
        key: 'competitions',
        name: 'Competitions',
        slides: this.state.competitionsSlides,
        link: '/compsOverview',
      };
      promoContent.push(comp);
    }

    const promos = promoContent
      .filter((promo) => promo.slides.length > 0)
      .map(function (promo, i) {
        return (
          <div key={promo.key}>
            {promo.slides.length > 1 && <PromoCarousel promo={promo} />}

            {promo.slides.length === 1 && <Promo promo={promo} />}
          </div>
        );
      });

    const isCustomiseMode = this.state.isCustomiseMode;

    const className = isCustomiseMode
      ? this.props.className + ' home-promo-customise-mode'
      : this.props.className;
    const saveLayoutButtonClassName = this.state.isDirty
      ? 'btn btn-primary btn-sm'
      : 'btn btn-primary btn-sm disabled';
    const saveLayoutButtonDisabled = !this.state.isDirty;
    const rowHeight = this.state.rowHeight;
    const breakpoints = {
      lg: 1200, //bootstrap xl
      md: 992, //bootstrap lg
      sm: 768, //bootstrap md
      xs: 576, //bootstrap sm
      xxs: 0, //bootstrap xs
    };

    return (
      <div className={className}>
        <div className="home-promo-customise">
          <button
            type="button"
            className="btn btn-sm btn-link btn-home-promo-customise mb-n3"
            onClick={this.handleCustomiseModeStateChange}
          >
            <i className="fas fa-cog" /> Customise this page
          </button>
          {isCustomiseMode && (
            <div className="home-promo-customise-instructions d-flex flex-row justify-content-between align-items-center">
              <span className="d-none d-md-inline">
                Click and drag the boxes below to re-arrange them
              </span>
              <button
                className={saveLayoutButtonClassName}
                disabled={saveLayoutButtonDisabled}
                onClick={this.handleSaveLayoutClick}
              >
                Save
              </button>
            </div>
          )}
        </div>
        {promoPositions != null && (
          <ResponsiveGridLayout
            layouts={promoPositions}
            rowHeight={rowHeight}
            margin={[30, 30]}
            isResizable={false}
            isDraggable={isCustomiseMode}
            onLayoutChange={this.handleLayoutChange}
            onBreakpointChange={this.handleBreakpointChange}
            draggableHandle=".customise-handle"
            breakpoints={breakpoints}
            cols={{ lg: 2, md: 2, sm: 2, xs: 1, xxs: 1 }}
          >
            {promos}
          </ResponsiveGridLayout>
        )}
      </div>
    );
  }
}
