import React, { Component } from 'react';
import NewsNavbar from '../components/newsNavbar';
import FooterNavbar from '../components/footerNavbar';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import './news.css';
import NewsUtils from '../utils/News';
import NewsNavWells from '../components/news/newsNavWells';

// Hyundai News -----------------------------------------------------------------------
import Future from '../components/news/hyundaiNews/future';
import Chelsea from '../components/news/hyundaiNews/chelsea';
import MostPositiveColour from '../components/news/hyundaiNews/mostPositiveColour';
import SmartPhoneKey from '../components/news/hyundaiNews/smartPhoneKey';
import Fountain from '../components/news/hyundaiNews/fountain';
import EvConcept from '../components/news/productNews/evConcept';
import AirTaxi from '../components/news/hyundaiNews/airTaxi';
import UrbanMobility from '../components/news/hyundaiNews/urbanMobility';
import NewTucson from '../components/news/newTucson';
import Mercury from '../components/news/hyundaiNews/mercury';
import NewI30N2 from '../components/news/newi30N2';
import NewI30N1 from '../components/news/newi30N1';
import AirMobility from '../components/news/hyundaiNews/airMobility';
import Pony from '../components/news/hyundaiNews/pony';
import HealthySeas from '../components/news/hyundaiNews/healthySeas';
import ElectricSUV from '../components/news/hyundaiNews/electricSUV';
import I20n from '../components/news/hyundaiNews/i20n';
import HyundaiEv from '../components/news/hyundaiNews/hyundaiEv';
import CustomerBrand from '../components/news/hyundaiNews/customerBrand';
import Commission from '../components/news/hyundaiNews/commission';
import FashionCollection from '../components/news/hyundaiNews/fashionCollection';
import IronMan from '../components/news/productNews/ironMan';
import Newi10 from '../components/news/productNews/newi10';
import WhatCar1 from '../components/news/productNews/whatCar1';
import KonaDiesel from '../components/news/productNews/konaDiesel';
import SantaFe from '../components/news/productNews/santaFe';
import Newi20 from '../components/news/productNews/newi20';
import KonaN from '../components/news/productNews/konaN';
import EvEvent from '../components/news/productNews/evEvent';
import Tucson from '../components/news/productNews/tucson';

import NewI30n1 from '../components/news/newi30N1';
import NewI30n2 from '../components/news/newi30N2';
import Ioniq5 from '../components/news/productNews/ioniq5';
import KonaElectric from '../components/news/productNews/konaElectric';
import Bayon from '../components/news/productNews/bayon';
import NewSantaFe from '../components/news/productNews/newSantaFe';
import TucsonNLine from '../components/news/productNews/tucsonNLine';
import OneMillionPound from '../components/news/hyundaiNews/oneMillionPound';
import WhatsInAName from '../components/news/hyundaiNews/whatsInaName';
import Expo2030 from '../components/news/hyundaiNews/expo2030';
import ExecutiveChair from '../components/news/hyundaiNews/executiveChair';
import LargestMarketShare from '../components/news/hyundaiNews/largestMarketShare';
import Ioniq6Saloon from '../components/news/hyundaiNews/ioniq6Saloon';
import ArcticEnvironment from '../components/news/hyundaiNews/arcticEnvironment';
import Newi203 from '../components/news/hyundaiNews/newi20';
import Ioniq5n from '../components/news/hyundaiNews/ioniq5n';
import NewKona23 from '../components/news/hyundaiNews/newKona23';
import KonaCamp from '../components/news/hyundaiNews/konaCamp';
import NewSantaFe23 from '../components/news/hyundaiNews/newSantaFe23';
import NewI2023 from '../components/news/hyundaiNews/newi2023';
import NewIoniq5n from '../components/news/hyundaiNews/newIoniq5n';
import NewBayon from '../components/news/hyundaiNews/newBayon';
import Npx1 from '../components/news/hyundaiNews/npx1';
import TucsonHybrid from '../components/news/hyundaiNews/tucsonHybrid';
import HyundaiTucson from '../components/news/hyundaiNews/hyundaiTucson';
import I5nPricing from '../components/news/hyundaiNews/i5npricing';
import Inster from '../components/news/hyundaiNews/inster';

export default class News extends Component {
  constructor(props) {
    super(props);

    var articles = [
      {
        name: 'inster',
        component: <Inster />,
      },
      {
        name: 'i5npricing',
        component: <I5nPricing />,
      },
      {
        name: 'hyundaiTucson',
        component: <HyundaiTucson />,
      },
      {
        name: 'tucsonHybrid',
        component: <TucsonHybrid />,
      },
      {
        name: 'npx1',
        component: <Npx1 />,
      },
      {
        name: 'newBayon',
        component: <NewBayon />,
      },
      {
        name: 'newIoniq5n',
        component: <NewIoniq5n />,
      },
      {
        name: 'newI2023',
        component: <NewI2023 />,
      },
      {
        name: 'newSantaFe23',
        component: <NewSantaFe23 />,
      },
      {
        name: 'konaCamp',
        component: <KonaCamp />,
      },
      {
        name: 'newKona23',
        component: <NewKona23 />,
      },
      {
        name: 'ioniq5n',
        component: <Ioniq5n />,
      },
      {
        name: 'newi20',
        component: <Newi203 />,
      },
      {
        name: 'arcticEnvironment',
        component: <ArcticEnvironment />,
      },
      {
        name: 'ioniq6Saloon',
        component: <Ioniq6Saloon />,
      },
      {
        name: 'executiveChair',
        component: <ExecutiveChair />,
      },
      {
        name: 'largestMarketShare',
        component: <LargestMarketShare />,
      },
      {
        name: 'exp02030',
        component: <Expo2030 />,
      },
      {
        name: 'whatsInaName',
        component: <WhatsInAName />,
      },
      {
        name: 'oneMillionPound',
        component: <OneMillionPound />,
      },
      {
        name: 'electricSUV',
        component: <ElectricSUV />,
      },
      {
        name: 'i20n',
        component: <I20n />,
      },
      {
        name: 'hyundaiEv',
        component: <HyundaiEv />,
      },
      {
        name: 'customerBrand',
        component: <CustomerBrand />,
      },
      {
        name: 'commission',
        component: <Commission />,
      },
      {
        name: 'fashionCollection',
        component: <FashionCollection />,
      },
      {
        name: 'pony',
        component: <Pony />,
      },
      {
        name: 'healthySeas',
        component: <HealthySeas />,
      },
      // {
      //   name: 'airMobility',
      //   component: <AirMobility />,
      // },
      {
        name: 'mercury',
        component: <Mercury />,
      },
      {
        name: 'newi30n2',
        component: <NewI30N2 />,
      },
      {
        name: 'newi30N1',
        component: <NewI30N1 />,
      },
      {
        name: 'newTucson',
        component: <NewTucson />,
      },
      {
        name: 'urbanMobility',
        component: <UrbanMobility />,
      },
      {
        name: 'airTaxi',
        component: <AirTaxi />,
      },
      {
        name: 'fountain',
        component: <Fountain />,
      },
      { name: 'evConcept', component: <EvConcept /> },
      {
        name: 'smartPhoneKey',
        component: <SmartPhoneKey />,
      },
      {
        name: 'future',
        component: <Future />,
      },
      {
        name: 'chelsea',
        component: <Chelsea />,
      },

      {
        name: 'positive-color',
        component: <MostPositiveColour />,
      },
      { name: 'konaN', component: <KonaN /> },
      { name: 'ioniq5', component: <Ioniq5 /> },
      { name: 'konaElectric', component: <KonaElectric /> },
      { name: 'Bayon', component: <Bayon /> },
      { name: 'newSantaFe', component: <NewSantaFe /> },
      { name: 'tusconNLine', component: <TucsonNLine /> },
      { name: 'newi20n2', component: <NewI30n2 /> },
      { name: 'newi30n1', component: <NewI30n1 /> },
      { name: 'newTucson', component: <NewTucson /> },
      { name: 'tucson', component: <Tucson /> },
      { name: 'evEvent', component: <EvEvent /> },
      { name: 'newi20', component: <Newi20 /> },
      { name: 'newi10', component: <Newi10 /> },
      { name: 'evConcept', component: <EvConcept /> },
      {
        name: 'ironMan',
        component: <IronMan />,
      },
      {
        name: 'whatCar1',
        component: <WhatCar1 />,
      },
      {
        name: 'konaDiesel',
        component: <KonaDiesel />,
      },
      {
        name: 'santaFe',
        component: <SantaFe />,
      },
    ];

    const displayItemName =
      this.props.location.query != null &&
      this.props.location.query.displayItem != null
        ? this.props.location.query.displayItem
        : null;

    if (displayItemName != null) {
      var itemIndex;
      for (var i = 0; i < articles.length; i++) {
        if (articles[i].name === displayItemName) {
          itemIndex = i;
          break;
        }
      }
      const articleRemoved = articles.splice(itemIndex, 1)[0];
      articles.splice(0, 0, articleRemoved);
    }

    this.state = {
      newsList: articles,
      displayCount: 2,
    };

    this.handleShowMoreClick = this.handleShowMoreClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    NewsUtils.attachThumbnailClick();
  }

  handleShowMoreClick() {
    this.setState((prevState) => {
      return {
        displayCount: prevState.displayCount + 2,
      };
    });
  }

  componentDidUpdate() {
    NewsUtils.attachThumbnailClick();
  }

  render() {
    const displayNews = this.state.newsList
      .slice(0, this.state.displayCount)
      .map((item, index) => (
        <div
          key={index}
          data-search-component={item.name}
          className="mb-5 w-100" // w-100 i.e. 11 fix
        >
          {React.cloneElement(item.component, {
            displayShowMore: true,
          })}
        </div>
      ));
    const displayMoreButton =
      this.state.displayCount < this.state.newsList.length;

    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />
        <NewsNavbar />

        <div className="container">
          <section className="section1 row mx-0">
            <div className="col-md-10 offset-md-1">
              <h4 className="h4ServHistory">News</h4>
              <p className="small">
                The latest 6 months news articles are listed below and all
                others can be viewed in the archive section.
              </p>
              <div className="row">{displayNews}</div>
            </div>

            {displayMoreButton && (
              <div className="col p-5">
                <div className="text-center">
                  <button
                    className="btn btn-primary removeRadius"
                    onClick={this.handleShowMoreClick}
                  >
                    Show more news
                  </button>
                </div>
              </div>
            )}
          </section>
        </div>

        <NewsNavWells />
        <FooterNavbar />
        <Footer />
      </div>
    );
  }
}
